<template>
  <div class="budget-page" v-if="!chooseEd">
    <div class="go">
      <div class="go-btn" @click="go">点击进入</div>
    </div>
    <div class="top">
      <fm-select :clearable="true" v-model="chooseId">
        <fm-option v-for="item in dataList" :key="item.id" :value="item.id" :label="item.label"></fm-option>
      </fm-select>
      <div class="status-text">{{chooseData ? chooseData.statusText : ''}}</div>
    </div>
  </div>
  <div class="budget-page" v-else>
    <div class="choose">
      <div class="choose-item" @click="itemAction(item)" v-for="item in actions" :key="item.key">
        <div class="img-d">
          <img :src="item.src" alt="">
        </div>
        <div class="btm-btn">
          <div class="go-btn btn-s">{{item.label}}</div>
        </div>
      </div>
    </div>
    <div class="back">
      <div class="go-btn" @click="chooseEd = false">重新选择</div>
    </div>
    <div class="top">
      <div style="margin-left: 0;" class="status-text">{{chooseData ? chooseData.label : ''}}</div>
    </div>
    <fm-modal title="会议材料" :mask-closable="false" v-model="openFile" @cancel="openFile = false" width="600px">
      <file-manage
        v-if="$authFunsProxy.viewFile && chooseData"
        :file-data-ids="chooseData.fileIds"
        :funs="fileFuns"
      />
    </fm-modal>
  </div>
</template>

<script>
import { budgetYearRequest } from '../../api'
import FileManage from '@/components/base/FileManage'

export default {
  components: { FileManage },
  name: 'BudgetHandle',
  data () {
    return {
      dataList: [],
      loading: false,
      chooseId: null,
      chooseEd: false,
      openFile: false
    }
  },
  computed: {
    typeList () {
      return this.$store.getters.budgetYearTypeList
    },
    fileFuns () {
      if (this.chooseData) {
        return {
          get: this.$authFunsProxy.viewFile,
          upload: false,
          del: false
        }
      } else {
        return {
          get: false,
          upload: false,
          del: false
        }
      }
    },
    actions () {
      let data = [{
        key: 'details',
        label: '填报与审批',
        src: '/static/images/budget/ren.png'
      },
      {
        key: 'info',
        label: '详细',
        src: '/static/images/budget/file.png'
      },
      {
        key: 'detailList',
        label: '明细',
        src: '/static/images/budget/file.png'
      },
      {
        key: 'file',
        label: '会议材料',
        src: '/static/images/budget/file.png'
      }]
      return data
    },
    chooseData () {
      return this.dataList.find(v => v.id === this.chooseId)
    }
  },
  methods: {
    itemAction (action) {
      if (action.key === 'info') {
        this.$router.push({
          name: 'internalcontrol.budget.batch_detail', query: {
            id: this.chooseData.id,
            title: this.chooseData.label
          }
        })
      } else if (action.key === 'detailList') {
        this.$router.push({
          name: 'internalcontrol.budget.detail_list', query: {
            id: this.chooseData.id,
            title: this.chooseData.label
          }
        })
      } else if (action.key === 'details') {
        this.$router.push({
          name: 'internalcontrol.budget.details', query: {
            id: this.chooseData.id,
            status: this.chooseData.status,
            type: 'handle'
          }
        })
      } else if (action.key === 'file') {
        this.openFile = true
      }
    },
    go () {
      if (!this.chooseId) {
        this.$notice.info({title: '请选择预算' })
        return
      }
      this.chooseEd = true
    },
    async loadData () {
      let res = await budgetYearRequest.getMy()
      this.dataList = res.map(item => {
        if (!this.chooseId && item.status !== 'end') {
          this.chooseId = item.id
        }
        item.label = item.year + ' ' + (item.type === 'normal' ? this.typeList[0].label : this.typeList[1].label) + ' ' + (item.name || '')
        item.fileIds = (item.fileIds || '').split(',').filter(v => v).map(v => Number(v))
        let actionCheck = {}
        item.actions.forEach(v => actionCheck[v.key] = true)
        item.actionCheck = actionCheck
        return item
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>
  .budget-page {
    margin: 16px 12px;
    background: #FFFFFF;
    border-radius: 6px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .top {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 92px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px;
      .status-text {
        margin-left: 58px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
    }
    .go {
      width: calc(60vh - 56px - 32px);
      height: calc(60vh - 56px - 32px);
      background: url('/static/images/budget/go.png') no-repeat;
      background-size: calc(60vh - 56px - 32px) calc(60vh - 56px - 32px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .go-btn {
      width: 200px;
      height: 32px;
      cursor: pointer;
      background: #3776ED;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: Helvetica;
      color: #FFFFFF;
    }
    .choose {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
    .choose-item {
      width: 191px;
      cursor: pointer;
      height: 244px;
      background: #F7FAFF;
      border-radius: 7px;
      // margin: 0 54px;
      border: 1px solid #3776ED;
      display: flex;
      flex-direction: column;
    }
    .btn-s {
      width: 158px;
      height: 32px;
    }
    .btm-btn {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 52px;
      height: 49px;
    }
    .img-d {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      img {
        width: 67px;
        height: 78px;
      }
    }
    .back {
      height: 120px;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
</style>